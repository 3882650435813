/* eslint-disable no-console */
/* eslint-disable no-nested-ternary */
const { GraphQLClient } = require('graphql-request');
const { decrypt } = require('@helper_encryption');
const { getAppEnv, getAccessEnv } = require('@root/core/helpers/env');
const { graphqlEndpoint, storeCode } = require('@config');

function requestGraph(query, variables = {}, context = {}, config = {}) {
    let token = '';
    if (config.token) {
        if (query.includes('snap_client_key')) {
            token = `Bearer ${getAccessEnv()}`;
        } else {
            token = `Bearer ${config.token}`;
        }
    } else if (context.session || context.headers) {
        if (query.includes('snap_client_key')) {
            token = `Bearer ${getAccessEnv()}`;
        } else {
            token = context.session.token
                ? `Bearer ${decrypt(context.session.token)}`
                : context.headers.authorization
                    ? context.headers.authorization
                    : '';
        }
    } else if (query.includes('snap_client_key')) {
        token = `Bearer ${getAccessEnv()}`;
    }

    let requestMethod = 'POST';
    if (query.includes('getVesMenu')) {
        requestMethod = 'GET';
    }

    return new Promise((resolve) => {
        const additionalHeader = storeCode ? { store: storeCode } : {};
        if (token && token !== '') {
            additionalHeader.Authorization = token;
        }
        const headers = {
            ...additionalHeader,
        };
        const appEnv = getAppEnv();
        const client = new GraphQLClient(`${graphqlEndpoint[appEnv] || graphqlEndpoint.prod}`, {
            headers,
            method: requestMethod,
            jsonSerializer: {
                parse: JSON.parse,
                stringify: JSON.stringify,
            },
        });
        client
            .request(query, variables)
            .then((data) => resolve(data))
            .catch((err) => resolve(err));
    });
}

module.exports = requestGraph;
